<template>
  <div id="project-finder-app">
    <div><Header /></div>
    <b-row class="body-container">
      <b-col cols="12" md="4">
        <div class="sidebar-container">
          <div class="driver-tracking-scroll-view" v-bind:class="{ vScroll: hasScroll }" ref="tbod">
            <div class="col-md-12">
              <table class="table b-table table-hover table-bordered ">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>名前</th>
                    <th width="100px">状態</th>
                    <th width="150px">最終更新</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id" @click="viewDriverTrackingDetail(item.id)">
                    <td>{{ item.id }}</td>
                    <td>{{ item.fullName }}</td>
                    <td :style="{ background: getRowColor(item.status)}">{{ item.status }}</td>
                    <td>{{ item.lastUpdated }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="8"><DriverTrackingMap /></b-col>
    </b-row>
  </div>
</template>

<script>
import Header from "@/views/Location/Header.vue";
import DriverTrackingMap from "@/views/Location/DriverTrackingMap";
import { mapState } from "vuex";
import router from '@/router'

export default {
  name: "DriverTrackingList",
  components: {
    Header,
    DriverTrackingMap,
  },

  computed: {
    ...mapState(["currentFeatures"]),
  },
  data() {
    return {
      items: [],
      color: '',
      hasScroll: false,
    };
  },
  mounted() {
    this.currentFeatures.features.map(feature => {
      this.items.push(feature.properties)
    }),
    this.$nextTick(() => {
      this.hasScroll = this.$refs.tbod.scrollHeight > this.$refs.tbod.clientHeight;
    });
  },
  methods: {
    //link to detail screen - not develop
    viewDriverTrackingDetail: function(id) {
      router.push({ name: 'DriverTrackingDetail', query: {'id': id}});
    },

    //get row color corresponding to driver status
    getRowColor(status){
      switch(status)
      {
        case "移動中":
          this.color = 'rgb(206,214,255)';
          break;
        case "退勤":
          this.color = 'rgb(231,231,231)';
          break;
        case "乗務前":
          this.color = 'rgb(255,255,183)';
          break;
        default:
          this.color = 'rgb(255,206,206)';
      }
      return this.color;
    }
  }
};
</script>

<style scoped>
@media (max-width: 1512px) and (min-width: 1200px) {
  .col-md-4{
    max-width: 100%;
    flex: 0 0 45.333333%;
  }
  .col-md-8{
    max-width: 100%;
    flex: 0 0 54.666667%;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .col-md-4{
    max-width: 100%;
    flex: 0 0 100%;
  }
  .col-md-8{
    max-width: 100%;
    flex: 0 0 100%;
  }
}

#project-finder-app{
  line-height: 1.2;
}

.body-container {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 7.1rem;
}
.navbar {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}
.driver-tracking-scroll-view {
  max-height: 86vh;
  overflow-y: auto;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
}
.vScroll {
  margin-right: 0px;
}
/* width */
.driver-tracking-scroll-view::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.driver-tracking-scroll-view::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 1px dotted rgb(12, 12, 12);
  border-radius: 5px;
}

.driver-tracking-scroll-view::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
.driver-tracking-scroll-view::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #C4C4C4;
  border: 1px solid #000000;
}

/* Handle on hover */
.driver-tracking-scroll-view::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 105);
}
.table-bordered{
  box-shadow: 0px 5px #d8d8d8;
}
.table-bordered thead th {
  background-color: rgb(231,231,231);
  border-bottom-width: 1px;
  border: 1px solid #080808;
  font-weight: normal; 
}

.table-bordered th, .table-bordered td {
  border: 1px solid #080808;
  font-size: 1.5rem;
}
</style>
