<template>
  <nav
    class="
      navbar navbar
      d-flex
      justify-content-between
      navbar-light
      bg-light
      navbar-expand
    "
    id="navbar"
  >
    <a href="#" target="_self" class="navbar-brand" @click="navigation()">mobican <img src="@/assets/truck-icon.png" width="85px" height="70px" style="padding-bottom: inherit"></a>
    <li class="navbar-text"><img src="@/assets/avatar-user.png" width="60px" height="60px"></li>
  </nav>
</template>

<script>
import router from '@/router'

export default {
  name: "Header",

  methods: {
    navigation: function() {
      if (this.$route.name != "DriverTrackingList") {
        router.push({ name: 'DriverTrackingList'});
      }
    }
  }
};
</script>

<style>
.body-container {
  margin-left: 0px;
  margin-right: 0px;
}
#navbar {
  margin-top: 0rem;
  margin-bottom: 1rem;
  padding: 0px 10px 10px 30px;
  border-radius: 0rem;
  border-bottom: 1px solid black;
  position: fixed;
  z-index: 1;
  width: -webkit-fill-available;
}
.navbar-brand {
  font-size: 3.5rem;
  padding-bottom: 10px;
}
</style>
